.container {
  /* background-color:rgb(157, 185, 245); */
  width: 100vw;
  height: 100vh;
  /* position: fixed; */
}
.innercontainer {
  width: 86vw;
  height: 86vh;
  margin-top: 7vh;
  margin-left: 7vw;
  border-radius: 20px;
  display: flex;
  position:fixed;
}
.leftcontainer img {
  width: 30vw;
  height: 86vh;
  border-radius: 20px;

  /* background-color: rgb(160, 103, 213); */
}
.leftcontainer {
  color: rgb(11, 11, 13);
}
.rightcontainer {
  width: 60vw;
  height: 86vh;
  background-color: rgb(231, 236, 248);
  border-radius: 20px;
  position: relative;
  right: 2.3vw;
}
.icons {
  position: relative;
  bottom: 600px;
  left: 100px;
}
.icon2 {
  margin-left: 90px;
}
.icon3 {
  margin-left: 80px;
}
.icon4 {
  margin-left: 2px;
  margin-right: 2px;
  position: relative;
  top: 2.5px;
}
.cityname {
  position: relative;
  bottom: 570px;
  left: 100px;
  font-weight: 500;
}

.date {
  position: relative;
  bottom: 560px;
  left: 100px;
  font-weight: 500;
}

.temp {
  position: relative;
  bottom: 620px;
  left: 190px;
}
.temperature {
  font-size: 60px;
  font-weight: 600;
}
.sunny {
  position: relative;
  bottom: 110px;
  left: 20px;
  font-size: 20px;
  font-weight: 500;
}
.timer {
  margin-left: 75px;
  position: relative;
  top: 2.5px;
}
.timer1 {
  margin-left: 83px;
  position: relative;
  top: 2.5px;
}
.today {
  margin-left: 15px;
}
.loc {
  position: relative;
  top: 2.5px;
}
.sun {
  position: relative;
  bottom: 62.5px;
  right: 15px;
}
.lessthan {
  position: relative;
  top: 118px;
  right: 43px;
}
.greaterthan {
  position: relative;
  bottom: 104.5px;
  left: 110px;
}
.girlimg {
  width: 30px;
  height: 30px;
  border-radius: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
}
.left {
  margin-top: 20px;
  margin-left: 30px;
}
.left span {
  font-size: 17px;
  font-weight: 700;
}
.left p {
  font-size: 12px;
  font-weight: 500;
  position: relative;
  bottom: 8px;
}
.right {
  margin-top: 20px;
  margin-right: 30px;
}
.dot {
  margin-right: 20px;
  margin-bottom: 7px;
}
.graph {
  width: 50vw;
  height: 30vh;
  background-color: white;
  border-radius: 10px;
  margin-left: 3vw;
}
.top p {
  font-weight: 500;
  margin-left: 30px;
}
.bottom {
  width: 50vw;
  height: 30vh;
  background-color: rgb(231, 236, 248);
  margin-left: 3vw;
}
.b-top {
  display: flex;
  justify-content: space-between;
}
.b-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.container1 {
  background-color: white;
  width: 15vw;
  height: 15vh;
  border-radius: 15px;
}
.iconss {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background-color: rgb(57, 43, 161);
  margin-right: 10px;
}
.input {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
}
.icn {
  font-size: 25px;
  color: white;
  
}
.icn1 {
  font-size: 20px;
  color: white;
  padding-left: 3px;
  padding-top: 2px;
  
}
.t-header {
  display: flex;
  justify-content: space-between;
}
.hour {
  margin-top: 10px;
  margin-left: 20px;
  font-weight: 500;
}
.rain-p {
  border: none;
  background-color: rgb(231, 236, 248);
  margin-right: 30px;
  margin-top: 10px;
  width: 130px;
  height: 27px;
  border-radius: 5px;
  font-weight: 500;
}
.days {
  width: 100px;
  height: 26px;
  margin-top: 10px;
  margin-right: 20px;
  background-color: rgb(231, 236, 248);
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  padding-top: 3px;
 
}
.right-s{
  display: flex;

}
.percent{
  margin-left: 80px;
  font-size: 18px;
  font-weight: 700;
}
.percentage{
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  
}
.humidity{
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  
}
.color1{
  width:50px;
  height: 10px;
  background-color: rgb(57, 43, 161);
  margin-top: -5px;
  border-radius: 10px;
 
}
.humidity{
  margin-top: -20px;
}
.color{
  width: 50px;
  height: 10px;
  margin-top: -5px;
  border-radius: 10px;
  background-color: rgb(231, 236, 248);

}
.innercolour1{
  width: 20px;
  height: 9px;
  border-radius: 10px;;
  background-color: rgb(57, 43, 161);
}
.content{
  position: relative;
  top: 8px;
}
.precipitation{
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  margin-top: 7px;
}
.p-color1{
  width: 30px;
  height: 10px;
  background-color:rgb(57, 43, 161) ;
  border-radius: 5px;
}
.p-color2{
  width: 30px;
  height: 10px;
  background-color:rgb(231, 236, 248) ;
  border-radius: 5px;
}
.pcolor{
  width: 16px;
  height: 10px;
  background-color:rgb(57, 43, 161) ;
  border-radius: 5px;
}
.p-color{
  width: 30px;
  height: 10px;
  background-color:rgb(231, 236, 248) ;
  border-radius: 5px;
}
.per{
  display: flex;
  justify-content: space-between;
  
}
.f-bar{
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background-color:rgb(231, 236, 248) ;
}
.fbar{
  width: 42%;
  height: 9px;
  border-radius: 10px;
  background-color:rgb(57, 43, 161) ;
}
.feels{
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 7px;
}
.rbar{
  width: 60%;
  height: 9px;
  border-radius: 10px;
  background-color:rgb(57, 43, 161) ;
}
.wind{
 position: relative;
 bottom: 25px;left: 5px;
 
}
.speed{
  position: relative;
  bottom: 160px;left: 70px;
  font-size: 18px;
  font-weight: 700;
 
}
.Apps{
  width: 900px;height: 180px;
  position: relative;
  bottom: 225px;left: 50px;
}
.list-h{
  width: 300px;
  position: relative;
  left: 67px; top: -10px;
  display: flex;
  justify-content: space-between;
}
.list-h li{
  list-style: none;
  font-size: 10px;
  font-weight: 600;
}
.list-t{
  width: 290px;
  position: relative;
  left: 72px;top: -2px;
  display: flex;
  justify-content: space-between;
}
.list-t li{
  list-style: none;
  font-size: 10px;
  font-weight: 600;
}
.list-i{
  width: 285px;
  position: relative;
  left: 73px;top: -5px;
  display: flex;
  justify-content: space-between;
}
.list-i li{
  list-style: none;
  font-size: 10px;
  font-weight: 600;
}
.addition{
  width: 250px;
  height: 150px;
  /* background-color: blueviolet; */
  
  font-size: 10px;
  position: relative;
  top:-40px ;left: 450px;
}
.add{
  width:200px ;
  height:20px ;
  font-size: 15px;
  background-color: rgb(231, 236, 248);
  text-align: center;
  border-radius: 5px;
}